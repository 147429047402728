import { graphql } from '../gql';

export const brandUsageDocument = graphql(`
  query brandUsage {
    whoAmI {
      ... on Manager {
        id
        company {
          id
          currentBrandCount
          currentSubscription {
            id
            subscriptionPlan {
              id
              maxBrandCount
            }
          }
        }
      }
    }
  }
`);

export const campaignUsageDocument = graphql(`
  query campaignUsage {
    whoAmI {
      ... on Manager {
        id
        company {
          id
          currentActiveCampaignCount
          currentSubscription {
            id
            subscriptionPlan {
              id
              maxCampaignCount
            }
          }
        }
      }
    }
  }
`);

export const storageSpaceUsageDocument = graphql(`
  query storageSpaceUsage {
    whoAmI {
      ... on Manager {
        id
        company {
          id
          usedStorageSpace
          currentSubscription {
            id
            subscriptionPlan {
              id
              storageSpaceLimit
            }
          }
        }
      }
    }
  }
`);

export const deleteManagerDocument = graphql(`
  mutation deleteManager {
    deleteManager
  }
`);

export const logoutManagerDocument = graphql(`
  mutation logoutManager {
    logoutManager
  }
`);

export const managerLayoutDocument = graphql(`
  query managerLayout {
    whoAmI {
      ... on Manager {
        id
        email
        locale
        company {
          id
          name
          currentSubscription {
            id
            status
            subscriptionPlan {
              id
            }
          }
          brands {
            id
          }
        }
      }
    }
  }
`);

export const adminDocument = graphql(`
  query admin {
    whoAmI {
      ... on Manager {
        id
        isAdmin
      }
    }
  }
`);

export const managersDocument = graphql(`
  query managers($first: Int!, $page: Int) {
    managers(first: $first, page: $page) {
      data {
        id
        email
        locale
        company {
          id
          name
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`);

export const impersonateManagerDocument = graphql(`
  mutation impersonateManager($id: ID!) {
    impersonateManager(id: $id)
  }
`);

export const forgotManagerPasswordDocument = graphql(`
  mutation forgotManagerPassword($input: ForgotManagerPasswordInput!) {
    forgotManagerPassword(input: $input)
  }
`);

export const loginManagerDocument = graphql(`
  mutation loginManager($input: LoginManagerInput!) {
    loginManager(input: $input)
  }
`);

export const managerDocument = graphql(`
  query managerDocument {
    whoAmI {
      ... on Manager {
        id
        email
        company {
          id
          managers {
            id
            email
          }
        }
      }
    }
  }
`);

export const updateManagerPasswordDocument = graphql(`
  mutation updateManagerPassword($input: UpdateManagerPasswordInput!) {
    updateManagerPassword(input: $input)
  }
`);

export const requestVerificationOfNewManagerEmailDocument = graphql(`
  mutation requestVerificationOfNewManagerEmail(
    $input: RequestVerificationOfNewManagerEmailInput!
  ) {
    requestVerificationOfNewManagerEmail(input: $input)
  }
`);

export const recoverManagerPasswordDocument = graphql(`
  mutation recoverManagerPassword($input: RecoverManagerPasswordInput!) {
    recoverManagerPassword(input: $input)
  }
`);

export const settingsDocument = graphql(`
  query settings {
    whoAmI {
      ... on Manager {
        id
        company {
          id
          currentSubscription {
            id
            subscriptionPlan {
              id
              name
              bookingFeePercentage
              minBookingFee
              maxCampaignCount
              maxBrandCount
              storageSpaceLimit
            }
          }
        }
      }
    }
  }
`);

export const updateManagerLocaleDocument = graphql(`
  mutation updateManagerLocale($input: UpdateManagerLocaleInput!) {
    updateManagerLocale(input: $input) {
      id
      locale
    }
  }
`);

export const verifyManagerEmailDocument = graphql(`
  mutation verifyManagerEmail($input: VerifyManagerEmailInput!) {
    verifyManagerEmail(input: $input)
  }
`);

export const resendManagerVerificationEmailDocument = graphql(`
  mutation resendManagerVerificationEmail(
    $input: ResendManagerVerificationEmailInput!
  ) {
    resendManagerVerificationEmail(input: $input)
  }
`);

export const verifyAndUpdateManagerEmailDocument = graphql(`
  mutation verifyAndUpdateManagerEmail($input: VerifyManagerEmailInput!) {
    verifyAndUpdateManagerEmail(input: $input) {
      id
      email
    }
  }
`);
