import { Stack, styled, Typography } from '@mui/material';

const HiddenText = styled(Typography, {
  name: 'HiddenText',
})(() => ({
  filter: 'blur(5px)',
  userSelect: 'none',
}));

interface DeliveryAddressProps {
  address: {
    recipientName: string;
    streetAddressLine1: string;
    streetAddressLine2?: string | undefined | null;
    postcode: string;
    city: string;
    country: string;
  };
  showApproximateAddress?: boolean;
}

export default function DeliveryAddress({
  address,
  showApproximateAddress,
}: DeliveryAddressProps) {
  if (showApproximateAddress) {
    return (
      <Stack>
        <HiddenText>{'********** **********'}</HiddenText>
        <HiddenText>{'************* ***'}</HiddenText>
        <HiddenText>{'******'}</HiddenText>
        <Typography>{`${address.postcode}, ${address.city}`}</Typography>
        <Typography>{address.country}</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography>{address.recipientName}</Typography>
      <Typography>{address.streetAddressLine1}</Typography>
      {address.streetAddressLine2 ? (
        <Typography>{address.streetAddressLine2}</Typography>
      ) : null}
      <Typography>{`${address.postcode}, ${address.city}`}</Typography>
      <Typography>{address.country}</Typography>
    </Stack>
  );
}
