import { Box, Container, Grid2, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { adminDocument } from '@social-garden/api/documents/manager.ts';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle.tsx';
import AdminCreatorsTab from './AdminCreatorsTab.tsx';
import AdminManagersTab from './AdminManagersTab.tsx';

export default function Admin() {
  const { t } = useTranslation(['manager']);
  const [value, setValue] = useState<number>(0);
  const { data } = useSuspenseQuery(adminDocument);

  const handleOnChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  if (data.whoAmI.__typename !== 'Manager') {
    throw new Error('Authenticated user is not a manager!');
  }

  if (!data.whoAmI.isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <Container maxWidth="xl">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <PageTitle>{t('manager:navigation.admin')}</PageTitle>
        </Grid2>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleOnChange}>
              <Tab label="Creators" />
              <Tab label="Managers" />
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {value === 0 ? <AdminCreatorsTab /> : null}
          {value === 1 ? <AdminManagersTab /> : null}
        </Grid2>
      </Grid2>
    </Container>
  );
}
