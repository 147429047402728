import { graphql } from '../gql';

export const campaignDocument = graphql(`
  query campaign($id: ID!) {
    campaign(id: $id) {
      ...campaign
    }
  }
`);

export const campaignWithCreatorHasCampaignDocument = graphql(`
  query campaignWithCreatorHasCampaign($id: ID!) {
    campaign(id: $id) {
      ...campaign
      creatorHasCampaign {
        id
        status
        contentUrl
        discontinuedReason
        rewardValue
        answers {
          id
          answer
          question {
            id
            question
          }
        }
        previewRevisions {
          id
          retriedReason
        }
      }
    }
  }
`);

export const dynamicMoneyRewardValueDocument = graphql(`
  query dynamicMoneyRewardValue($campaignId: ID!) {
    dynamicMoneyRewardValue(campaignId: $campaignId)
  }
`);

export const campaignYouAreApplyingForDocument = graphql(`
  query campaignYouAreApplyingFor($id: ID!) {
    campaign(id: $id) {
      id
      headerImage {
        hash
        uri
      }
      name
      ...campaignTypeable
      rewardType
      fixedMoneyRewardValue
      questions {
        id
        question
      }
      brand {
        ...brand
      }
      gifts {
        id
        type
      }
    }
  }
`);

export const campaignGiftDocument = graphql(`
  query campaignGift($id: ID!) {
    campaign(id: $id) {
      id
      gifts {
        id
        type
        name
        description
      }
      creatorHasCampaign {
        id
        campaignGiftCodes {
          id
          code
          gift {
            id
          }
        }
      }
    }
  }
`);

export const discoverCampaignsDocument = graphql(`
  query discoverCampaigns(
    $input: DiscoverCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    discoverCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        name
        ...campaignTypeable
        headerImage {
          uri
          hash
        }
        brand {
          ...brand
        }
        category {
          id
          name
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
      }
    }
  }
`);

export const campaignWithStatisticsDocument = graphql(`
  query campaignWithStatistics($id: ID!) {
    campaign(id: $id) {
      ...campaign
      statistics {
        ...campaignStatistics
      }
    }
  }
`);

export const campaignBriefingDocument = graphql(`
  query campaignBriefing($id: ID!) {
    campaign(id: $id) {
      ...campaign
    }
  }
`);

export const publishCampaignDocument = graphql(`
  mutation publishCampaign($id: ID!) {
    publishCampaign(id: $id) {
      id
      status
    }
  }
`);

export const deleteCampaignDocument = graphql(`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(id: $id)
  }
`);

export const campaignReportDocument = graphql(`
  query campaignReport($id: ID!) {
    campaign(id: $id) {
      id
      name
      status
      headerImage {
        uri
        hash
      }
      brand {
        ...brand
      }
      category {
        id
        name
      }
      ...campaignTypeable
      reportable {
        ... on InstagramReelCampaignReport {
          id
          completedCount
          followerCount
          plays
          likes
          comments
          shares
          reach
          engagementRate
          cpv
          price
          creatorHasCampaignReports {
            id
            creatorHasCampaign {
              id
              status
              creator {
                id
                username
              }
            }
            followerCount
            plays
            likes
            comments
            shares
            reach
            engagementRate
            cpv
            price
          }
        }
        ... on InstagramPostCampaignReport {
          id
          completedCount
          followerCount
          views
          saves
          engagementRate
          cpv
          price
          creatorHasCampaignReports {
            id
            creatorHasCampaign {
              id
              status
              creator {
                id
                username
              }
            }
            followerCount
            views
            saves
            engagementRate
            cpv
            price
          }
        }
        ... on InstagramStoryCampaignReport {
          id
          completedCount
          followerCount
          views
          engagementRate
          cpv
          price
          creatorHasCampaignReports {
            id
            creatorHasCampaign {
              id
              status
              creator {
                id
                username
              }
            }
            followerCount
            views
            engagementRate
            cpv
            price
          }
        }
        ... on TiktokVideoCampaignReport {
          id
          completedCount
          followerCount
          views
          likes
          comments
          shares
          engagementRate
          cpv
          price
          creatorHasCampaignReports {
            id
            creatorHasCampaign {
              id
              status
              creator {
                id
                username
              }
            }
            followerCount
            views
            likes
            comments
            shares
            engagementRate
            cpv
            price
          }
        }
        ... on YoutubeVideoCampaignReport {
          id
          completedCount
          subscriberCount
          views
          likes
          comments
          favorites
          engagementRate
          cpv
          price
          creatorHasCampaignReports {
            id
            creatorHasCampaign {
              id
              status
              creator {
                id
                username
              }
            }
            subscriberCount
            views
            likes
            comments
            favorites
            engagementRate
            cpv
            price
          }
        }
      }
    }
  }
`);

export const campaignReportsDocument = graphql(`
  query campaignReports($brandIds: [ID!]!, $first: Int!, $page: Int) {
    campaigns(
      input: { brandIds: $brandIds, status: [ACTIVE, COMPLETED] }
      first: $first
      page: $page
    ) {
      data {
        id
        name
        reportable {
          ... on InstagramReelCampaignReport {
            id
            completedCount
            followerCount
            cpv
            price
          }
          ... on InstagramPostCampaignReport {
            id
            completedCount
            followerCount
            cpv
            price
          }
          ... on InstagramStoryCampaignReport {
            id
            completedCount
            followerCount
            cpv
            price
          }
          ... on TiktokVideoCampaignReport {
            id
            completedCount
            followerCount
            cpv
            price
          }
          ... on YoutubeVideoCampaignReport {
            id
            completedCount
            subscriberCount
            cpv
            price
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const campaignsDocument = graphql(`
  query campaigns($input: CampaignsInput!, $first: Int!, $page: Int) {
    campaigns(input: $input, first: $first, page: $page) {
      data {
        id
        name
        status
        headerImage {
          uri
          hash
        }
        brand {
          ...brand
        }
        ...campaignTypeable
        statistics {
          id
          applicationsToReview
          previewsToReview
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const createCampaignDocument = graphql(`
  mutation createCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      ...campaign
    }
  }
`);

export const updateCampaignDocument = graphql(`
  mutation updateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      ...campaign
    }
  }
`);

export const updateCampaignHeaderImageDocument = graphql(`
  mutation updateCampaignHeaderImage($input: UpdateCampaignHeaderImageInput!) {
    updateCampaignHeaderImage(input: $input) {
      id
      headerImage {
        uri
        hash
      }
    }
  }
`);

export const completeCampaignDocument = graphql(`
  mutation completeCampaign($id: ID!) {
    completeCampaign(id: $id) {
      id
      status
    }
  }
`);

export const campaignPreviewDocument = graphql(`
  query campaignPreview($id: ID!) {
    campaign(id: $id) {
      id
      name
      description
      status
      applyUntil
      updatedAt
      publishedAt
      address
      includeContentFileOnSubmission
      rewardType
      fixedMoneyRewardValue
      ...campaignTypeable
      headerImage {
        uri
        hash
      }
      brand {
        ...brand
      }
      category {
        id
        name
      }
      gifts {
        id
        name
        type
      }
      taskItems {
        id
        type
        taskable {
          ... on WebsiteTask {
            id
            url
          }
          ... on HashtagTask {
            id
            hashtags
          }
          ... on FreeTextTask {
            id
            description
          }
        }
      }
    }
  }
`);

export const instagramPostDocument = graphql(`
  query instagramPost($id: ID!) {
    campaign(id: $id) {
      id
      name
      applyUntil
      rewardType
      fixedMoneyRewardValue
      ...campaignTypeable
      headerImage {
        uri
        hash
      }
      gifts {
        id
        name
      }
    }
  }
`);

export const discoverCampaignsForSitemapDocument = graphql(`
  query discoverCampaignsForSitemap(
    $input: DiscoverCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    discoverCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        updatedAt
      }
    }
  }
`);

export const discoverCampaignsForInstagramPostDocument = graphql(`
  query discoverCampaignsForInstagramPost(
    $input: DiscoverCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    discoverCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        description
      }
    }
  }
`);

export const publicDiscoverCampaignsDocument = graphql(`
  query publicDiscoverCampaigns(
    $input: DiscoverCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    discoverCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        name
        ...campaignTypeable
        headerImage {
          uri
          hash
        }
        brand {
          ...brand
        }
        category {
          id
          name
        }
      }
    }
  }
`);
