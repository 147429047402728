import { graphql } from '../gql';

export const registerCreatorDocument = graphql(`
  mutation registerCreator($input: RegisterCreatorInput!) {
    registerCreator(input: $input) {
      ...creator
      locale
      hasSSOLogin
    }
  }
`);

export const socialRegisterCreatorDocument = graphql(`
  mutation socialRegisterCreator($input: SocialRegisterCreatorInput!) {
    socialRegisterCreator(input: $input)
  }
`);

export const deactivateCreatorDocument = graphql(`
  mutation deactivateCreator {
    deactivateCreator
  }
`);

export const forgotCreatorPasswordDocument = graphql(`
  mutation forgotCreatorPassword($input: ForgotCreatorPasswordInput!) {
    forgotCreatorPassword(input: $input)
  }
`);

export const creatorProfileDocument = graphql(`
  query creatorProfile {
    whoAmI {
      ... on Creator {
        ...creator
        locale
        channels {
          ...channelWithAccessTokenValid
        }
      }
    }
  }
`);

export const recoverCreatorPasswordDocument = graphql(`
  mutation recoverCreatorPassword($input: RecoverCreatorPasswordInput!) {
    recoverCreatorPassword(input: $input)
  }
`);

export const verifyCreatorEmailDocument = graphql(`
  mutation verifyCreatorEmail($input: VerifyCreatorEmailInput!) {
    verifyCreatorEmail(input: $input)
  }
`);

export const resendCreatorVerificationEmailDocument = graphql(`
  mutation resendCreatorVerificationEmail(
    $input: ResendCreatorVerificationEmailInput!
  ) {
    resendCreatorVerificationEmail(input: $input)
  }
`);

export const requestVerificationOfNewCreatorEmailDocument = graphql(`
  mutation requestVerificationOfNewCreatorEmail(
    $input: RequestVerificationOfNewCreatorEmailInput!
  ) {
    requestVerificationOfNewCreatorEmail(input: $input)
  }
`);

export const verifyAndUpdateCreatorEmailDocument = graphql(`
  mutation verifyAndUpdateCreatorEmail($input: VerifyCreatorEmailInput!) {
    verifyAndUpdateCreatorEmail(input: $input) {
      id
    }
  }
`);

export const updateCreatorProfileDocument = graphql(`
  mutation updateCreatorProfile($input: UpdateCreatorProfileInput!) {
    updateCreatorProfile(input: $input) {
      ...creator
    }
  }
`);

export const updateCreatorLocaleDocument = graphql(`
  mutation updateCreatorLocale($input: UpdateCreatorLocaleInput!) {
    updateCreatorLocale(input: $input) {
      id
      locale
    }
  }
`);

export const updateCreatorPasswordDocument = graphql(`
  mutation updateCreatorPassword($input: UpdateCreatorPasswordInput!) {
    updateCreatorPassword(input: $input)
  }
`);

export const uploadCreatorProfilePictureDocument = graphql(`
  mutation uploadCreatorProfilePicture(
    $input: UploadCreatorProfilePictureInput!
  ) {
    uploadCreatorProfilePicture(input: $input) {
      id
      profilePicture {
        uri
        hash
      }
    }
  }
`);

export const creatorContextDocument = graphql(`
  query creatorContext {
    whoAmI {
      ... on Creator {
        ...creator
        locale
        hasSSOLogin
        channels {
          ...channelWithAccessTokenValid
        }
      }
    }
  }
`);

export const loginCreatorDocument = graphql(`
  mutation loginCreator($input: LoginCreatorInput!) {
    loginCreator(input: $input)
  }
`);

export const logoutCreatorDocument = graphql(`
  mutation logoutCreator {
    logoutCreator
  }
`);

export const socialLoginCreatorDocument = graphql(`
  mutation socialLoginCreator($input: SocialLoginCreatorInput!) {
    socialLoginCreator(input: $input)
  }
`);

export const setCreatorExpoPushTokenDocument = graphql(`
  mutation setCreatorExpoPushToken($input: SetCreatorExpoPushTokenInput!) {
    setCreatorExpoPushToken(input: $input)
  }
`);

export const creatorsDocument = graphql(`
  query creators($first: Int!, $page: Int) {
    creators(first: $first, page: $page) {
      data {
        id
        username
        locale
        isUnreliable
        channels {
          id
          channelableType
        }
      }
      paginatorInfo {
        total
      }
    }
  }
`);

export const impersonateCreatorDocument = graphql(`
  mutation impersonateCreator($id: ID!) {
    impersonateCreator(id: $id)
  }
`);
