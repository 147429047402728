import { useQuery } from '@apollo/client';
import { previewFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useCallback, useRef } from 'react';
import SuspenseFallback from './SuspenseFallback.tsx';

interface ContentPreviewProps {
  style?: CSSProperties;
  id: string;
  mediaType: 'image' | 'video';
}

export default function ContentPreview({
  style,
  id,
  mediaType,
}: ContentPreviewProps) {
  const { t } = useTranslation(['common']);

  const videoRef = useRef<HTMLVideoElement>(null);
  const sourceRef = useRef<HTMLSourceElement>(null);

  const { data, loading, error, refetch } = useQuery(previewFileDocument, {
    variables: {
      id,
    },
  });

  const handleOnVideoError = useCallback(async () => {
    const response = await refetch();
    if (videoRef.current === null || sourceRef.current === null) {
      return;
    }
    sourceRef.current.src = response.data.previewFile.uri;
    videoRef.current.load();
    await videoRef.current.play();
  }, [refetch]);

  if (loading && data === undefined) {
    return <SuspenseFallback />;
  }

  if (error || data === undefined) {
    return null;
  }

  switch (mediaType) {
    case 'image':
      return (
        <img
          style={{
            display: 'block',
            width: '100%',
            minHeight: 250,
            maxHeight: '80vh',
            objectFit: 'contain',
            ...style,
          }}
          src={data.previewFile.uri}
          alt="Preview"
        />
      );
    case 'video':
      return (
        <video
          ref={videoRef}
          style={{
            display: 'block',
            width: '100%',
            minHeight: 250,
            maxHeight: '80vh',
            objectFit: 'contain',
            ...style,
          }}
          controls
          autoPlay={false}
          controlsList="nodownload"
          preload="none">
          <source
            ref={sourceRef}
            src={data.previewFile.uri}
            onError={handleOnVideoError}
          />
          <track kind="captions" />
          {t('common:videoplayer.notSupported')}
        </video>
      );
    default:
      assertUnreachable(mediaType);
  }
}
